import * as React from 'react';
import * as Yup from 'yup';

import Box from '@appbuckets/react-ui/Box';
import buildMutationFormAction from '../_lib/buildMutationFormAction';
import HookedInput from '@appbuckets/react-ui-forms/HookedInput';

interface LoginProps {
  onSuccess?: () => void;
}

interface LoginDto {
  email: string;
  password: string;
}

const loginSchema: Yup.SchemaOf<LoginDto> = (
  Yup.object({
    email: Yup.string().email('Email Invalido').required('Email è obbligatoria'),
    password: Yup.string().required('Password è obbligatoria')
  })
);

const LoginAction = buildMutationFormAction<LoginDto, LoginProps, any>({
  displayName: 'LoginAction',

  schema: loginSchema,

  defaultProps: () => ({
    modal: true,
    modalProps: {
      header: 'Accedi'
    },
    submitButton: 'Accedi',
    cancelButton: 'Annulla'
  }),

  Content: () => (
    <React.Fragment>
      <Box mb={4}>
        <p>
          Inserisci le tue credenziali per accedere
        </p>
      </Box>

      <HookedInput
        name={'email'}
        label={'Indirizzo Email'}
        type={'email'}
        required
        autoComplete={'email'}
      />
      <HookedInput
        name={'password'}
        label={'Password'}
        type={'password'}
        required
        autoComplete={'current-password'}
      />
    </React.Fragment>
  ),

  invalidateQueries: [
    [ 'user' ]
  ],

  onMutate: async (data, client) => {
    return client.loginWithUsernameAndPassword(data.email, data.password);
  },


  toast: {
    onCreatingSubmit: 'Accesso effettuato con successo!',
    onError: 'thrown'
  }
});

LoginAction.displayName = 'LoginAction';

export default LoginAction;
