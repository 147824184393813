import * as React from 'react';

import Box from '@appbuckets/react-ui/Box';
import Column from '@appbuckets/react-ui/Column';
import Divider from '@appbuckets/react-ui/Divider';
import Icon from '@appbuckets/react-ui/Icon';
import Row from '@appbuckets/react-ui/Row';
import { useUserScope } from '../../../init/UserScopeProvider';


// ---
// Component Render
// ----
const Footer = () => {
  const { environmentDetails } = useUserScope();
  
  return (
    <Box textAlign={'center'} pb={8} mt={8} pt={6} >
      <Divider className={'mb-8'} />
      <div>
        <Row>
          {environmentDetails?.privacyPolicyUrl && (
            <Column>
              <a
                href={environmentDetails.privacyPolicyUrl}
                target={'_blank'}
              >
                Informativa sulla Privacy
              </a>
            </Column>
          )}
          
          {environmentDetails?.accessibilityStatementUrl && (
            <Column>
              <a
                href={environmentDetails.accessibilityStatementUrl}
                target={'_blank'}
              >
                Dichiarazione di accessibilità
                <Icon name={'external-link'} className={'ml-2'} />
              </a>
            </Column>
          )}
        </Row>

        {environmentDetails?.extraUrl && (
          <Box className={'mt-6'}>
            <p className={'has-font-bold'}>
              <Icon name={'pen-to-square'}/>
              Valuta Questo Sito:
              <a href={environmentDetails.extraUrl} className={'ml-2'} target={'_blank'} style={{ textDecoration: 'underline' }}>
                RISPONDI AL QUESTIONARIO
              </a>
            </p>
          </Box>
        )}
        
      </div>
      
      <Box textColor={'cloud'} mt={6}>
        Made with <Icon name={'heart'} /> by{' '}
        <a target={'_blank'} href={'https://proedis.net'} style={{ color: 'inherit' }}>Proedis S.r.l.</a>
      </Box>
    </Box>
  );
};

Footer.displayName = 'Footer';

export default React.memo(Footer);
