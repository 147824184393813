import * as React from 'react';

import Button from '@appbuckets/react-ui/Button';
import Icon from '@appbuckets/react-ui/Icon';
import LoginAction from '../../../actions/Login/Login';
import CreateAccountAction from '../../../actions/Signup/Signup';

import { useUserScope } from '../../../init/UserScopeProvider';
import { IdentityProvider } from '../../../interfaces/enums';

import { AppLink } from '../../index';
import type { PageComponent } from '../../index';


import AdminDropdownMenu from './atoms/AdminDropdownMenu';
import NotificationButton from './atoms/NotificationsButton';

import UserDropdownMenu from './atoms/UserDropdownMenu';


// ----
// Component Render
// ----
const Navbar: PageComponent = (props) => {

  // ----
  // Props Deconstruct
  // ----
  const {
    appState: {
      userHasAuth
    }
  } = props;


  // ----
  // Internal Hooks
  // ----
  const { userData, environmentDetails } = useUserScope();
  const appLogo = environmentDetails?.logoUrl || '' ;

  // ----
  // Component Render
  // ----
  return (
    <header>
      <nav>
        <div className={'navbar-content phone'}>
          <AppLink to={'Home'}>
            <div className={'navbar-item'}>
              <Icon name={'home'} />
              <span>Home</span>
            </div>
          </AppLink>
          <AppLink to={'DonateItem'}>
            <div className={'navbar-item'}>
              <Icon name={'plus'} />
              <span>Inserisci</span>
            </div>
          </AppLink>
          {userHasAuth && (
            <AdminDropdownMenu
              inverted
              trigger={(
                <div className={'navbar-item'}>
                  <Icon name={'tools'} />
                  <span>Amministratore</span>
                </div>
              )}
            />
          )}
          <UserDropdownMenu
            trigger={(
              <div className={'navbar-item'}>
                <Icon name={'user-circle'} iconStyle={'far'} />
                <span>Tu</span>
              </div>
            )}
          />
          {!userHasAuth && environmentDetails?.authMode !== IdentityProvider.Email && (
            <div>
              <AppLink to={'Login'}>
                <Button primary>Entra</Button>
              </AppLink>
            </div>
          )}

          {!userHasAuth && environmentDetails?.authMode === IdentityProvider.Email && (
            <React.Fragment>
              <CreateAccountAction
                trigger={(
                  <Button primary icon={<Icon iconStyle={'fas'} name={'user-plus'} />} content={'Registrati'}/>
                )}
              />
              <LoginAction
                trigger={(
                  <Button primary icon={<Icon iconStyle={'fas'} name={'user'} />} content={'Entra'}/>
                )}
              />
            </React.Fragment>
          )}
        </div>
        <div className={'navbar-content no-phone'}>
          <div className={'app-logo'}>
            <AppLink to={'Home'}>
              {appLogo ? (
                <img src={appLogo} alt={'App logo'} width={'auto'} />
              ) : 'Bacheca del Riutilizzo'}
            </AppLink>
          </div>
          <div className={'app-actions'}>

            {!userHasAuth && environmentDetails?.authMode === IdentityProvider.Email && (
              <React.Fragment>
                <CreateAccountAction
                  trigger={(
                    <Button primary icon={<Icon iconStyle={'fas'} name={'user-plus'} />} content={'Registrati'}/>
                  )}
                />
                <LoginAction
                  trigger={(
                    <Button primary icon={<Icon iconStyle={'fas'} name={'user'} />} content={'Entra'}/>
                  )}
                />
              </React.Fragment>
            )}


            {!userHasAuth && environmentDetails?.authMode !== IdentityProvider.Email && (
              <AppLink to={'Login'}>
                <Button
                  primary
                  content={'Entra'}
                  icon={<Icon iconStyle={'fas'} name={'user'} />}
                />
              </AppLink>
            )}

            {userHasAuth && userData && (
              <React.Fragment>
                <NotificationButton />
                <AdminDropdownMenu
                  inverted
                  trigger={(
                    <Button warning icon={'tools'} />
                  )}
                />
                <UserDropdownMenu
                  trigger={(
                    <Button
                      icon={{ name: 'user-circle', iconStyle: 'far' }}
                      content={userData.firstName}
                    />
                  )}
                />
                <Button
                  success
                  as={AppLink}
                  to={'DonateItem'}
                  icon={'plus'}
                  content={'Inserisci'}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

Navbar.displayName = 'Navbar';

export default Navbar;
