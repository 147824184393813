import * as React from 'react';
import * as Yup from 'yup';

import Box from '@appbuckets/react-ui/Box';
import { IdentityProvider } from '../../interfaces/enums';
import buildMutationFormAction from '../_lib/buildMutationFormAction';
import HookedInput from '@appbuckets/react-ui-forms/HookedInput';


/* --------
 * Internal Types
 * -------- */
interface CreateAccountProps {
  /** Optional callback after success */
  onSuccess?: () => void;
}

interface CreateAccountDto {
  firstName: string;

  lastName: string;

  email: string;

  password: string;

  repeatPassword?: string;

}

/* --------
 * Schema
 * -------- */
const createAccountSchema: Yup.SchemaOf<CreateAccountDto> = (
  Yup.object({
    firstName: Yup.string().required('Nome è obbligatorio'),
    lastName: Yup.string().required('Cognome è obbligatorio'),
    email: Yup.string().email('Email Invalido').required('Email è obbligatoria'),
    password: Yup.string()
      .min(8, 'Password deve avere almeno 8 caratteri')
      .required('Password è obbligatoria'),
    repeatPassword: Yup.string().oneOf([ Yup.ref('password'), null ], 'Le Password non corrispondono')
  })
);


/* --------
 * Action Definition
 * -------- */
const CreateAccountAction = buildMutationFormAction<CreateAccountDto, CreateAccountProps, any>({
  displayName: 'CreateAccountAction',

  schema: createAccountSchema,

  defaultProps: () => ({
    modal: true,
    modalProps: {
      header: 'Crea Account'
    },
    submitButton: 'Sign up',
    cancelButton: 'Annulla'
  }),

  Content: () => (
    <React.Fragment>
      <Box mb={4}>
        <p className={'mb-2'}>
          Stai per create un account
        </p>
        <p>
          Creando un account, accetti i nostri Termini di Servizio e l'Informativa sulla Privacy.
        </p>
      </Box>

      <HookedInput
        name={'firstName'}
        label={'Nome'}
        required
        autoComplete={'name'}
      />

      <HookedInput
        name={'lastName'}
        label={'Cognome'}
        autoComplete={'name'}
        required
      />

      <HookedInput
        name={'email'}
        label={'Indirizzo Email'}
        type={'email'}
        required
        autoComplete={'email'}
      />

      <HookedInput
        name={'password'}
        label={'Password'}
        type={'password'}
        required
        autoComplete={'new-password'}
        helpText={'Deve Avere almeno 8 caratteri'}
      />

      <HookedInput
        name={'repeatPassword'}
        label={'Repeat Password'}
        type={'password'}
        required
        helpText={'Repeat il password'}
      />

    </React.Fragment>
  ),


  onMutate:  (data, client) => (
    client.request({
      method: 'POST',
      url: 'create-account',
      withAccessToken: false,
      data: {
        username: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        identityProvider: IdentityProvider.Email
        
      },
    })
  ),

  invalidateQueries: [ 'user' ],


  toast: {
    onCreatingSubmit: 'Account Creato',
    onError: 'thrown'
  }
});


CreateAccountAction.displayName = 'CreateAccountAction';

export default CreateAccountAction;
