import * as React from 'react';

import { useQueryClient, useMutation } from 'react-query';
import type { MutationKey, QueryClient, QueryKey, UseMutationResult } from 'react-query';

import type { Client, ClientRequestError } from '@appbuckets/react-app-client';

import type { AnyObject } from '@appbuckets/react-ui-core';

import { buildFormAction } from '@appbuckets/react-ui-smart-components';
import type { FormBuilderConfig, FormComponent, FormComponentProps } from '@appbuckets/react-ui-smart-components';

import { useClient } from '@appbuckets/react-app-client';

import type { AccountCompleteDto } from '../../interfaces/entities';
import type { ClientStorage } from '../../init/ClientProvider';


/* --------
 * Internal Types
 * -------- */
interface MutationFormBuilderConfig<Dto extends AnyObject, Props extends {}, Result>
  extends FormBuilderConfig<Dto, WrappedFormActionProps<Dto, Props, Result>, Result> {
  /** A set of query to be invalidated */
  invalidateQueries?: QueryKey[] | ((props: Props) => QueryKey[]);

  /** Set o optional mutation key */
  mutationKey?: MutationKey;

  /** The mutate function to use */
  onMutate: (data: Dto, client: Client<AccountCompleteDto, ClientStorage>, props: Props) => Promise<Result>;
}

type WrappedFormActionProps<Dto extends AnyObject, Props extends {}, Result> = Props & {
  /** The instance of query client */
  queryClient: QueryClient;

  /** The use mutation hook */
  mutate: UseMutationResult<Result, ClientRequestError, Dto, Props>;
};


/* --------
 * Builder Definition
 * -------- */
export default function buildMutationFormAction<Dto extends AnyObject, Props extends {}, Result>(
  configuration: MutationFormBuilderConfig<Dto, Props, Result>
): React.FunctionComponent<FormComponentProps<Dto, Props, Result>> {

  /** Strip extra configuration from provided one */
  const {
    /** Specific Mutation Form Action */
    invalidateQueries,
    mutationKey,
    onMutate,

    /** Overridden Configuration */
    onSubmit: userDefinedOnSubmitAction,

    /** Original builder configuration object */
    ...originalFormBuilderConfiguration
  } = configuration;


  /** Build the base Form */
  const BaseFormAction = buildFormAction<Dto, WrappedFormActionProps<Dto, Props, Result>, Result>({
    /** Pass down the original form builder configuration */
    ...originalFormBuilderConfiguration,

    /** Override the original onSubmit Handler */
    onSubmit: async (data, helpers, props) => {
      /** Get the use mutation hook from props */
      const { mutate } = props;

      /** Check if a user defined onSubmit function exists */
      if (typeof userDefinedOnSubmitAction === 'function') {
        await userDefinedOnSubmitAction(data, helpers, props);
      }

      /** Execute the Mutation */
      return mutate.mutateAsync(data);
    }
  });


  /** Build a wrapper component */
  const BuildMutationFormActionWrapper: FormComponent<Dto, Props, Result> = (
    props
  ) => {

    // ----
    // Internal Hooks
    // ----
    const client = useClient();
    const queryClient = useQueryClient();


    // ----
    // Build main Mutate Function
    // ----
    const mutate = useMutation<Result, ClientRequestError, Dto, Props>({
      mutationFn: (data) => onMutate(data, client, props),
      mutationKey,
      onMutate  : () => props,
      onSuccess : async () => {
        /** Get query to invalidate */
        const queryToInvalidate = typeof invalidateQueries === 'function'
          ? invalidateQueries(props)
          : invalidateQueries;

        /** Abort if no query to be invalidated exists */
        if (!Array.isArray(queryToInvalidate)) {
          return;
        }

        /** Build a set of invalidation promises */
        const invalidationPromises: Promise<void>[] = queryToInvalidate
          .filter((key) => typeof key === 'string' || Array.isArray(key))
          .map((key) => (
            queryClient.invalidateQueries(key)
          ));

        /** Await all invalidation */
        await Promise.all(invalidationPromises);
      }
    });


    // ----
    // Component Render
    // ----
    return (
      // @ts-ignore
      <BaseFormAction
        {...props}
        queryClient={queryClient}
        mutate={mutate}
      />
    );
  };

  /** Set the display name */
  BuildMutationFormActionWrapper.displayName = `Mutation${originalFormBuilderConfiguration.displayName}Wrapper`;

  /** Return the complete action builder */
  return BuildMutationFormActionWrapper;
}
