import * as React from 'react';

import type { DropdownMenuProps } from '@appbuckets/react-ui/DropdownMenu';
import DropdownMenu from '@appbuckets/react-ui/DropdownMenu';

import { useClient, useClientState } from '@appbuckets/react-app-client';

import { AppLink } from '../../../index';

import type { AccountCompleteDto } from '../../../../interfaces/entities';
import { IdentityProvider } from '../../../../interfaces/enums';
import { useUserScope } from '../../../../init/UserScopeProvider';


const UserDropdownMenu: React.VoidFunctionComponent<DropdownMenuProps> = (props) => {

  // ----
  // Internal Hooks
  // ----
  const { hasAuth, userData } = useClientState<AccountCompleteDto>();
  const { environmentDetails } = useUserScope();
  const client = useClient();

  const createLogoutUrl = React.useCallback((baseUrl: string | undefined, endpoint: string) => {
    try {
      // Use a safe default if baseUrl is undefined
      const safeBaseUrl = baseUrl || '';
      const url = new URL(safeBaseUrl);

      url.searchParams.append('action', 'logout');
      url.searchParams.append(
        'redirect_uri',
        `https://${environmentDetails.baseUrl}`
      );

      return url.toString();
    }
    catch (error) {
      return;
    }
  }, [ environmentDetails?.baseUrl ]);

  const spidLogout = React.useMemo(() =>
    createLogoutUrl(environmentDetails?.digitalIdentitySpidUrl, 'spid-logout'), [ createLogoutUrl, environmentDetails?.digitalIdentitySpidUrl ]
  );

  const cieLogout = React.useMemo(() =>
    createLogoutUrl(environmentDetails?.digitalIdentityCieUrl, 'cie-logout'), [ createLogoutUrl, environmentDetails?.digitalIdentityCieUrl ]
  );


  // ----
  // Handlers
  // ----
  const handleClientLogout = React.useCallback(
    async () => {
      await client.logout();

      if (userData?.identityProvider === IdentityProvider.Email) {
        return;
      }

      const url = userData?.identityProvider === IdentityProvider.Spid ? spidLogout : cieLogout;

      if (url) {
        window.location.replace(url.toString());
      }
    },
    [ cieLogout, client, spidLogout, userData?.identityProvider ]
  );


  // ----
  // Component Render
  // ----
  if (!userData || !hasAuth) {
    return null;
  }

  return (
    <DropdownMenu
      {...props}
      items={[
        { key: 0, icon: 'boxes', as: AppLink, to: 'ItemsMine', content: 'I miei Oggetti' },
        { key: 1, icon: 'question', as: AppLink, to: 'BookRequests', content: 'Richieste di Ritiro' },
        { key: 2, icon: 'bell', as: AppLink, to: 'Notifications', content: 'Notifiche' },
        { key: 3, icon: 'heart', as: AppLink, to: 'FavoriteItems', content: 'Preferiti' },
        { key: 4, icon: 'gem', as: AppLink, to: 'Wishlists', content: 'Wishlist' },
        // { key: 5, icon: 'cog', as: AppLink, to: 'AccountManagement', content: 'Il tuo Account' },
        { key: 6, icon: 'sign-out-alt', content: 'Esci', onClick: handleClientLogout }
      ]}
    />
  );
};

UserDropdownMenu.displayName = 'UserDropdownMenu';

export default UserDropdownMenu;
